<template>
  <div class="position-relative">
    <g-card :isHeader="false">
      <template #customHeader>
        <div class="px-2 pt-4">
          <div class="row">
            <div class="col-sm-7 col-md-6 col-lg-8">
              <h5 class="font-weight-bold">Pending Orders</h5>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 offset-1">
              <h5 class="font-weight-bold">Ready Orders</h5>
            </div>
          </div>
        </div>
      </template>
      <template #cardBody>
        <PendingOrders />
      </template>
    </g-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PendingOrders from '@/components/molecules/PendingOrders';
export default {
  name: 'PendingOrdersCard',
  components: {
    PendingOrders,
  },
  data() {
    return {
      filterVal: 'all',
    };
  },
  methods: {
    ...mapActions('history', ['filterHistory']),
  },
};
</script>

<style scoped></style>
