<template>
  <div class="home">
    <b-container fluid>
      <b-row>
        <b-col md="11" class="px-0">
          <div class="content-left history pt-4 pb-0">
            <!-- <Sidebar /> -->
            <div class="main-left mb-2">
              <div class="row mx-1">
                <div class="col-md-12 mb-5 mt-3">
                  <StockManagementCard/>
                </div>
              </div>
            </div>
           <Sidebar />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Sidebar from '@/components/layout/MainSidebar';
import StockManagementCard from '@/components/organisms/StockManagementCard';
// @ is an alias to /src
import '@/assets/css/style.css';
export default {
  name: 'Stock',
  components: {
    Sidebar,
    StockManagementCard,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
/* .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: calc(100vw - 150px) !important;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 !important;
} */
 .main-left{
height:min-content;
}
</style>
