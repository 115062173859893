var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getWhatModal == 'addons')?_c('b-modal',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-xl",modifiers:{"modal-xl":true}}],ref:"modal-primary",staticClass:"select-addons",attrs:{"id":"modal-primary","hide-footer":"","no-close-on-backdrop":"","title":'Select Addons',"size":'xl',"centered":""},on:{"hide":function($event){return _vm.hideModal('')}}},[(_vm.selectedProduct && _vm.selectedProduct.addons)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.selectedAddonModifier).length == 0),expression:"Object.keys(selectedAddonModifier).length == 0"}]},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',_vm._l((_vm.selectedProduct.addons),function(addon){return _c('b-col',{key:addon.id,staticClass:"mb-4",attrs:{"lg":12,"md":8,"sm":12}},[_c('b-card',{staticClass:"card"},[_c('b-card-header',{staticStyle:{"background-color":"#f4eade","border-radius":"14px","border":"none"}},[_c('span',{staticClass:"addon-description"},[_vm._v(_vm._s(addon.modifier && addon.modifier.description))]),_c('span',{staticClass:"addon-max-selection float-right badge badge-info"},[_vm._v("Max selection: "+_vm._s(addon.max_selection))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(addon.required),expression:"addon.required"}],staticClass:"badge required-badge ml-1"},[_vm._v("Required")])]),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mt-3"},_vm._l((addon.addon_products),function(product){return _c('b-col',{key:product.id,class:[
                    'p-2',
                    'cursor-pointer',
                    'addon-item',
                    {
                      selected: _vm.isProductSelected(addon, product),
                    },
                  ],attrs:{"lg":3,"md":6,"sm":12},on:{"click":function($event){return _vm.addOne(addon, product)}}},[_c('h6',{staticClass:"text-center pt-1"},[_vm._v(" "+_vm._s(product.product && product.product.name)+" ")]),_c('p',{staticClass:"text-center"},[_vm._v(" $"+_vm._s(product.product && product.product.sale_price)+" ")]),(_vm.isVisibleQUantity(addon, product))?_c('QuantityButton',{attrs:{"disabledBtn":_vm.isSelectQuantityDisable(addon, product),"value":_vm.getSelectedCountFor(addon, product)},on:{"decrement":function($event){return _vm.minusOne(addon, product)},"increment":function($event){return _vm.plusOne(addon, product)}}}):_vm._e(),(
                      product.product &&
                      product.product.stock &&
                      product.product.stock.tags &&
                      product.product.stock.tags.length > 0
                    )?_c('div',_vm._l((JSON.parse(
                        product.product.stock.tags
                      )),function(tag,index){return _c('img',{key:index,class:{
                        'left-tag': index == 0,
                        'right-tag': index == 1,
                      },attrs:{"src":tag,"alt":'Tag ' + (index + 1),"width":"30"}})}),0):_vm._e()],1)}),1)],1)],1)],1)}),1)],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],staticClass:"mt-3 float-right",staticStyle:{"background-color":"#0f3442"},attrs:{"disabled":_vm.disable},on:{"click":function($event){return _vm.showModal('')}}},[_vm._v(" Continue ")])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.selectedAddonModifier).length > 0),expression:"Object.keys(selectedAddonModifier).length > 0"}]},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"justify-content-center"},_vm._l((_vm.selectedAddonModifier.addons),function(addon){return _c('b-col',{key:addon.id,staticClass:"mb-4",attrs:{"lg":12,"md":12,"sm":12}},[_c('b-card',[_c('b-card-header',{staticClass:"description-header row",staticStyle:{"border-radius":"14px"}},[_c('div',{staticClass:"col-lg-6 d-flex justify-content-lg-start justify-content-center px-sm-2 px-0 mb-lg-0 mb-2"},[_c('span',{staticClass:"addon-description"},[_vm._v(_vm._s(addon.modifier && addon.modifier.description))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(addon.required),expression:"addon.required"}],staticClass:"badge required-badge ml-1"},[_vm._v("Required")])]),_c('div',{staticClass:"col-lg-6 d-flex justify-content-lg-end justify-content-center"},[_c('span',{staticClass:"addon-max-selection float-right badge badge-info"},[_vm._v("Max selection: "+_vm._s(addon.max_selection))])])]),_c('b-container',{staticClass:"d-flex justify-content-center",attrs:{"fluid":""}},[_c('b-row',{staticClass:"mt-3 w-100"},_vm._l((addon.addon_products),function(product){return _c('b-col',{key:product.id,class:[
                    'p-2',
                    'cursor-pointer',
                    'addon-item',
                    {
                      selected: _vm.isAddonSelected(addon, product),
                    },
                  ],attrs:{"xl":3,"sm":12},on:{"click":function($event){return _vm.addOne(addon, product)}}},[_c('h6',{staticClass:"text-center"},[_vm._v(" "+_vm._s(product.product && product.product.name)+" ")]),_c('p',{staticClass:"text-center"},[_vm._v(" $"+_vm._s(product.product && product.product.sale_price)+" ")]),(_vm.isVisibleQUantity(addon, product))?_c('QuantityButton',{attrs:{"disabledBtn":_vm.isSelectQuantityDisable(addon, product),"value":_vm.getSelectedCountFor(addon, product)},on:{"decrement":function($event){return _vm.minusOne(addon, product)},"increment":function($event){return _vm.plusOne(addon, product)}}}):_vm._e(),(
                      product.product &&
                      product.product.stock &&
                      product.product.stock.tags &&
                      product.product.stock.tags.length > 0
                    )?_c('div',_vm._l((JSON.parse(
                        product.product.stock.tags
                      )),function(tag,index){return _c('img',{key:index,class:{
                        'left-tag': index == 0,
                        'right-tag': index == 1,
                      },attrs:{"src":tag,"alt":'Tag ' + (index + 1),"width":"30"}})}),0):_vm._e()],1)}),1)],1)],1)],1)}),1)],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],staticClass:"mt-3 float-right",staticStyle:{"background-color":"#0f3442"},attrs:{"disabled":_vm.SubAddonsDisable},on:{"click":function($event){return _vm.clearSelectedAddons()}}},[_vm._v(" Continue ")])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }