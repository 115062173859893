import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import VueLazyload from 'vue-lazyload';
import VueCurrencyFilter from 'vue-currency-filter';
import VueSweetalert2 from 'vue-sweetalert2';
import VueCryptojs from 'vue-cryptojs';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueHtmlToPaper from 'vue-html-to-paper';
import '@/globalComponents.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-toast-notification/dist/theme-default.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-search-select/dist/VueSearchSelect.css';
import VueSocketIO from 'vue-socket.io';
import Echo from 'laravel-echo';

const options = {
  styles: ['https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css'],
};

Vue.use(BootstrapVue);
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
});
Vue.use(VueCryptojs);
Vue.use(VueSweetalert2);
Vue.use(VueLazyload);
Vue.use(VueToast, {
  position: 'bottom-left',
});
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

Vue.directive('focus', {
  inserted: (el) => {
    el.focus();
  },
});


window.pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '095f520b-dfa9-43f3-8389-eb039f8a0e9c',
    wsHost: 'master.harveys.sg',
    wssPort: 6001,
    cluster: 'ap1',
    encrypted: true, // Use true for HTTPS,
    useTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'], // Specify available transports
})


Vue.use(VueHtmlToPaper, options);
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
