<template>
  <div>
    <div
      class="d-flex align-items-center position-fixed"
      :style="{ top: '20px', left: '0px', zIndex: '9999' }"
    >
      <p
        v-if="connected"
        class="d-flex align-items-center px-3 py-1 m-0 mx-3 border border-success bg-success text-white font-bold"
        :style="{ borderRadius: '30px' }"
      >
        <span
          class="rounded bg-white p-1"
          :style="{ width: '10px', height: '10px' }"
          >&nbsp;</span
        >
        &nbsp;Connected
      </p>

      <p
        v-if="!connected"
        class="d-flex align-items-center px-3 py-1 m-0 mx-3 border border-danger bg-danger text-white font-bold"
        :style="{ borderRadius: '30px' }"
      >
        <span
          class="rounded bg-white p-1"
          :style="{ width: '10px', height: '10px' }"
          >&nbsp;</span
        >
        &nbsp;Disconnected
      </p>

      <button @click="hardReload()" class="btn mx-1 d-lg-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="26"
          height="26"
          viewBox="0 0 30 30"
        >
          <path
            d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"
          ></path>
        </svg>
      </button>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-8">
        <div
          class="col-sm-12 col-xl-11 col-lg-10 col-md-10 d-xl-flex flex-xl-row flex-wrap"
        >
          <div
            class="col-sm-12 col-md-12 col-lg-8 col-xl-4 mb-4"
            v-for="(order, key) in localOrdersPending"
            :key="key"
          >
            <div class="card shadow-lg overflow-hidden radius-20">
              <div
                class="card-header bg-success text-white d-flex align-items-center justify-content-between mb-3"
              >
                <div class="px-3">
                  <p class="mb-0 font-weight-bold">#{{ order.code }}</p>
                  <p class="mb-0 text-lg font-weight-light">
                    {{ getTimeDiff(order.created_at) }}
                  </p>
                </div>
                <div>
                  <img
                    class="cursor-pointer"
                    style="height: 24px; width: 24px"
                    src="@/assets/images/cancel.png"
                    alt="Close"
                    @click="cancel(order.id)"
                  />
                </div>
              </div>
              <div
                class="card-body px-3 py-2 product"
                v-for="(product, index) in order.orderProducts"
                :key="index"
              >
                <div class="border-bottom mb-1">
                  <p class="text-left order">
                    {{ product.quantity }}x {{ product.product_name }}
                  </p>
                </div>
                <!-- <list-component :addons="product.addons" :key="index"/> -->
                <ul class="list-unstyled">
                <li
                  class="d-flex align-items-center border-bottom mb-2"
                  v-for="(addon, i) in product.addons"
                  :key="i"
                >
                  <p class="order-list px-2 text-left mb-0 flex-grow-1">
                    <small class="">
                      <span>{{ addon.quantity }}x {{ addon.product_name }}</span>
                    </small>
                  </p>
                </li>
              </ul>
              </div>
              <div
                class="col-sm-10 col-lg-10 col-md-8 d-flex justify-content-center offset-2 mb-2"
              >
                <span
                  @click="printOrder(order)"
                  class="w-100 button-hover cursor-pointer d-inline-block btn btn-outline-dark rounded-pill px-4 py-1 text-lg font-weight-light text-secondary mr-2 mb-2"
                >
                  Print
                </span>
                <span
                  @click="ready(order.id)"
                  class="w-100 button-hover cursor-pointer d-inline-block btn btn-outline-dark rounded-pill px-4 py-1 text-lg font-weight-light text-secondary mr-2 mb-2"
                >
                  Ready
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-5 offset-sm+1 col-lg-4 col-xl-3 offset-xl-1">
        <div class="row">
          <div class="col-sm-6 col-md-9 col-lg-12">
            <div
              class="col-sm-12 mb-3"
              v-for="(order, key) in localOrdersReady"
              :key="key"
            >
              <div class="card shadow-lg rounded-3 overflow-hidden radius-20">
                <div
                  class="card-header bg-info text-white d-flex align-items-center justify-content-between"
                >
                  <div class="px-3 py-1">
                    <p class="mb-0 font-weight-bold">#{{ order.code }}</p>
                  </div>
                </div>
                <div
                  class="card-body p-2"
                  v-for="(product, index) in order.orderProducts"
                  :key="index"
                >
                  <div class="border-bottom mb-1">
                    <p class="text-left order">
                      {{ product.quantity }}x {{ product.product_name }}
                    </p>
                  </div>
                  <list-component :addons="product.addons" />
                </div>

                <div class="mb-2 order-btn">
                  <div class="col-sm-2 col-lg-6 d-flex justify-content-center">
                    <span
                      @click="serve(order.id)"
                      class="button-hover cursor-pointer d-inline-block btn btn-outline-dark rounded-pill px-4 py-1 text-lg font-weight-light text-secondary mr-2 mb-2"
                    >
                      Serve
                    </span>
                  </div>
                  <div class="col-sm-2 col-lg-6 d-flex justify-content-center">
                    <span
                      @click="reminder(order.id)"
                      class="button-hover cursor-pointer d-inline-block btn btn-outline-dark rounded-pill px-4 py-1 text-lg font-weight-light text-secondary mr-2 mb-2"
                    >
                      Reminder
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ListComponent from "./ListComponent.vue";
import { mapGetters, mapActions } from "vuex";
import mixins from "@/components/mixins/swal";
import { getSettings } from "../../apis/User";

import {
  readyOrder,
  cancelOrder,
  serveOrder,
  sendReadyOrderReminder,
} from "../../apis/Order";

export default {
  components: {
    ListComponent,
  },
  mixins: [mixins],
  data() {
    return {
      localOrdersPending: [], // Local orders array initialized empty
      localOrdersReady: [], // Local orders array initialized empty
      branch: this.$store.getters["auth/branch"], // Branch info from Vuex store
      loading: false, // For loading state
      ip: null,
      port: null,
      connected: true,
    };
  },
  computed: {
    ...mapGetters("history", ["getPendingAndReadyOrders"]),
    ...mapGetters("user", ["getDetailUser"]),
    orders() {
      return this.getPendingAndReadyOrders;
    },
    ordersData() {
      return this.orders;
    },
  },
  created() {
    window.Echo.connector.pusher.connection.bind("state_change", (states) => {
      console.log(states.current);
      if (states.current == "unavailable") {
        this.connected = false;
      }
    });

    window.Echo.connector.pusher.connection.bind("disconnected", () => {
      this.connected = false;
      console.log("Socket disconnected");
      // You can also display UI notifications, trigger reconnection logic, etc.
    });

    window.Echo.connector.pusher.connection.bind("offline", () => {
      this.connected = false;
      console.log("Socket disconnected");
      // You can also display UI notifications, trigger reconnection logic, etc.
    });

    // Optionally, listen for 'connected' and 'reconnecting' events
    window.Echo.connector.pusher.connection.bind("connected", () => {
      this.connected = true;
      console.log("Socket connected");
    });

    window.Echo.connector.pusher.connection.bind("reconnecting", (attempt) => {
      this.connected = false;
      console.log(`Attempting to reconnect... (Attempt: ${attempt})`);
    });

    // Assuming you've already set up Laravel window.Echo with Pusher
    window.Echo.connector.pusher.connection.bind("error", (error) => {
      console.error("WebSocket connection error:", error);
      this.connected = false;
    });

    window.Echo.channel("order-cancelled-channel.1").listen(
      ".Modules\\Order\\app\\Events\\OrderCancelledEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.localOrdersPending = this.localOrdersPending.filter(
          (order) => order.id !== data.id
        );
      }
    );

    window.Echo.channel("order-placing-channel.1").listen(
      ".Modules\\Order\\app\\Events\\OrderPlacedEvent",
      async (e) => {
        const order = JSON.parse(e.order);
        if (!this.localOrdersPending.find((local) => local.id == order.id)) {
          this.localOrdersPending.push(order);
          //this.printOrder(order);
        }
      }
    );

    window.Echo.channel("ready-order-channel.1").listen(
      ".Modules\\Order\\app\\Events\\OrderReadyEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        if (!this.localOrdersReady.find((local) => local.id == data.id)) {
          this.localOrdersReady.push(data);
        }
        const index = this.localOrdersPending.findIndex(
          (order) => order.id == data.id
        );
        console.log(index)
        if (index != -1) {
          this.localOrdersPending.splice(index, 1);
        }
        console.log(this.localOrdersPending)
      }
    );

    window.Echo.channel("order-served-channel.1").listen(
      ".Modules\\Order\\app\\Events\\OrderServedEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        const index = this.localOrdersReady.findIndex(
          (order) => order.id == data.id
        );
        if (index != -1) {
          this.localOrdersReady.splice(index, 1);
        }
      }
    );

    setInterval(() => {
      this.updateTimeDifference();
    }, 1000);
  },
  async mounted() {
    await this.fetchPendingAndReadyOrders();
    this.getSettingsData();
  },
  watch: {
    // Watch for changes in the `orders` prop and update `localOrders`
    orders: {
      handler(newOrders) {
        this.localOrdersPending = [...newOrders].filter(
          (order) => order.status == "ongoing"
        );
        this.localOrdersReady = [...newOrders].filter(
          (order) => order.status == "ready"
        );
      },
      immediate: true,
      deep: true, // Ensure the watcher runs when the component is created
    },
    connected: {
      handler(newValue, oldValue) {
        if (!newValue) {
          setTimeout(() => {
            window.location.reload();
          }, 20000);
        }
      },
    },
  },
  methods: {
    ...mapActions("history", [
      "fetchPendingAndReadyOrders",
      "removePendingOrder",
    ]),
    hardReload() {
      window.location.reload()
    },
    getSettingsData() {
      const token = localStorage.getItem("token");
      const branchId = localStorage.getItem("branchId");

      getSettings({ token: token, branchId: branchId })
        .then((response) => {
          if (response.status) {
            const data = response.data.data;
            (this.port = data.port), (this.ip = data.ip);
          }
          // this.toastSuccess('Configuration updated successfully!');
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    // Method to handle order cancellation
    printOrder(order) {
      console.log(order)
      const postData = {
        data: order,
        config: {
          ip: this.ip,
          port: this.port,
        },
      };
      console.log("postData", postData);
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify(postData));
      } else {
        this.toastError("Some error occurred while printing");
      }
    },
    ready(id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem("token");

      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };
      readyOrder(requestData)
        .then((response) => {
          if (response.status) {
            // this.fetchPendingAndReadyOrders();

            // const postData = {
            //   data: response.data.data,
            //   config: {
            //     ip: this.ip,
            //     port: this.port,
            //   },
            // };
            // console.log("postData", postData);
            // if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
            //   window.ReactNativeWebView.postMessage(JSON.stringify(postData));
            // } else {
            //   this.toastError("Some error occurred while printing");
            // }
            this.toastSuccess("Order ready successfully!");

            // return;
            // const print = this.print(response.data.data);
            // console.log(print);
            // if (print) {
            //   this.toastSuccess("Order ready successfully!");
            // } else {
            //   this.toastError("Some error occurred while printing");
            // }
          } else {
            console.log("error", response.message);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    cancel(id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem("token");

      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };
      cancelOrder(requestData)
        .then((response) => {
          if (response.status) {
            this.fetchPendingAndReadyOrders();
            this.toastSuccess("Order cancelled successfully!");
          } else {
            console.log("error", response.message);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    serve(id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem("token");

      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };
      serveOrder(requestData)
        .then((response) => {
          if (response.status) {
            this.fetchPendingAndReadyOrders();
            this.toastSuccess("Order serve successfully!");
          } else {
            console.log("error", response.message);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    reminder(id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem("token");

      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };
      sendReadyOrderReminder(requestData)
        .then((response) => {
          if (response.status) {
            this.toastSuccess(response.data.message);
          } else {
            console.log("error", response.message);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    getTimeDiff(dateTime) {
      const orderTime = new Date(dateTime).getTime();
      const now = new Date().getTime();
      let seconds = Math.floor((now - orderTime) / 1000);
      /*
       * Display hours and minutes
       */
      var minutes = "";

      let interval = seconds / 3600;
      if (interval > 1) {
        var hours = Math.floor(interval);
        minutes = Math.floor((interval % 1) * 60);
        if (hours < 10) {
          hours = `0${hours}`;
        }
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }
        return `${hours} : ${minutes}`;
      }

      /*
       * Display minutes and seconds
       */
      interval = seconds / 60;
      if (interval > 1) {
        minutes = Math.floor(interval);
        var sec = Math.floor((interval % 1) * 60);
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }
        if (sec < 10) {
          sec = `0${sec}`;
        }
        return `${minutes} : ${sec}`;
      }

      /**
       * displays seconds
       */
      return `00:${seconds}`;
    },
    /**
     * updates time difference every second
     */
    updateTimeDifference() {
      this.ordersData.forEach((order, index) => {
        this.ordersData[index] = {
          ...this.ordersData[index],
          created_at: order.created_at,
        };
      });
    },

    print(data) {
      const postData = {
        data: data,
        config: {
          ip: this.ip,
          port: this.port,
        },
      };
      console.log("postData", postData);
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify(postData));
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>  
<style>
.button-hover:hover {
  color: white !important;
}
.radius-20 {
  border-radius: 20px !important;
}
.order {
  font-size: x-large;
  font-weight: 600;
}

.pending-card {
  /* min-width:15rem; */
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
@media (max-width: 844px) {
  .order-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1024px) {
  .order-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 12rem;
  }
}
@media (max-width: 1023px) {
  .order-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card {
    min-width: 15rem;
  }
}
@media (max-width: 844px) {
  .order {
    font-size: large;
    font-weight: 900;
  }
}
  .form-checked {
    border-radius: 40px;
  }
  @media(min-width:845px){
    .order-list{
   font-size: x-large;
  }
  }
  
  @media (max-width:844px){
   .order-list{
    font-size: large;
    font-weight:500;
   }
  }

</style>