import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Main/HomePage'
import OrdersHistory from '../views/Main/HistoryPage'
import StockManagement from '../views/Main/StockManagement'
import PendingOrders from '../views/Main/HistoryPage/pendingOrders.vue'
import CancelledOrders from '../views/Main/HistoryPage/cancelledOrders.vue'
import Product from '../views/Main/ProductPage'
import Main from '../views/Main/index.vue'
import Auth from '../views/Auth'
import Login from '../views/Auth/LoginPage'
import Profile from '../views/Main/ProfilePage'
import Users from '../views/Main/UsersPage'
import store from '../store'
import NotFound from '../views/404.vue'
import OrderStatus from '../views/OrderStatus.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: Auth,
    redirect: {
      name: 'Login',
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
    ],
  },
  {
    path: '/desk',
    name: 'Main',
    component: Main,
    redirect: {
      name: 'Home',
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home,
      },
      {
        path: 'ready-orders',
        name: 'History',
        component: OrdersHistory,
      },
      {
        path: 'pending-orders',
        name: 'Pending',
        component: PendingOrders,
      },
      {
        path: 'cancelled-orders',
        name: 'Cancelled',
        component: CancelledOrders,
      },
      {
        path: 'product',
        name: 'Product',
        component: Product,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: 'stock-managment',
        name: 'Stock',
        component: StockManagement,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
        meta: {
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: '/404',
    component: NotFound,
    name: 'PageNotFound',
  },
  {
    path: '/orders-status',
    component: OrderStatus,
    name: 'OrderStatus',
  },
  {
    path: '*',
    redirect: {
      name: 'PageNotFound',
    },
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters['auth/isLogin']) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresVisit)) {
    if (store.getters['auth/isLogin']) {
      next({
        path: '/desk',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
