<template>
    <ul class="list-unstyled">
      <li
        class="d-flex align-items-center border-bottom mb-2"
        v-for="(addon, i) in localAddons"
        :key="i"
      >
        <p class="order-list px-2 text-left mb-0 flex-grow-1">
          <small class=""
            <span>{{ addon.quantity }}x {{ addon.product_name }}</span>
          </small>
        </p>
      </li>
    </ul>
  </template>
  <script>
  export default {
    props: ["addons"],
    data() {
      return {
        // Create a local copy of the addons prop and add the checked property
        localAddons: this.addons.map((addon) => ({
          ...addon,
          checked: false, // Add a checked property to track checkbox state
        })),
      };
    },
  };
  </script>
    <style scoped>
  .form-checked {
    border-radius: 40px;
  }
  @media(min-width:845px){
    .order-list{
   font-size: x-large;
  }
  }
  
  @media (max-width:844px){
   .order-list{
    font-size: large;
    font-weight:500;
   }
  }
  </style>  