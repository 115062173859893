<template>
  <div class="list-chart">
    <CartItem class="items" v-for="(cart, index) in carts" :cart="cart" :key="index" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CartItem from '@/components/molecules/CartItem'
export default {
  name: 'CartList',
  components: {
    CartItem
  },
  computed: mapState('cart', ['carts'])
  
}
</script>

<style scoped>

@media screen and (max-width:768px) {
.items{
  font-size: smaller;
  text-wrap:wrap;
  width: 10rem;
  padding:0;
}
}
@media screen and (max-width:1024px) {
.items{
  font-size: smaller;
  text-wrap:wrap;
  width:20rem;
  padding:0;
}
}
</style>
