<template>
    <div class="d-flex justify-content-center">
      <b-input-group class=" d-flex align-items-center w-md-50">
        <!-- Minus Button -->
        <b-input-group-prepend class="py-2">
          <b-button @click.stop="decrement" variant="dark" class="input-group-prepend rounded-circle d-flex align-items-center justify-content-center py-1 qty_btn qty_minus"  :disabled="disabledBtn">-</b-button>
        </b-input-group-prepend>
  
        <!-- Input Field -->
        <b-form-input
          v-model="value"
          readonly
          min="0"
          class="text-center rounded-pill"
          aria-label="Number input with plus minus buttons"
        ></b-form-input>
  
        <!-- Plus Button -->
        <b-input-group-append class="py-2">
          <b-button @click.stop="increment" variant="dark"  class="input-group-prepend rounded-circle d-flex align-items-center justify-content-center py-1 bg-black qty_btn qty_plus" :disabled="disabledBtn" >+</b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </template>
  
  <script>
  export default {
    props: [
      'value',
      'disabledBtn'
    ],
    data() {
      return {
        
      };
    },
    methods: {
      increment() {
        // this.value++;
        this.$emit('increment');
        
      },
      decrement() {
        // if (this.value > 0) this.value--; // prevent going below 0
        this.$emit('decrement');
      },
    },
  };
  </script>
  <style scoped>
  .qty_btn{
      width: 30px;
      height: 30px;
  }

  .qty_minus{
    position: relative !important;
    z-index: 99 !important;
    left: 17px !important;
  }
  .qty_plus{
    position: relative !important;
    z-index: 99 !important;
    left: -17px !important;
  }
  .w-md-50{
      width: 145px !important;
    }

/* @media only screen and (min-width: 768px) {
    .w-md-50{
      width: 145px !important;
    }
} */
  

</style>