import Api from './Api';

const END_POINT = 'auth';
const token = localStorage.getItem('token');

export default {
  detail(id) {
    return Api.get(`${END_POINT}/getUserById/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export async function getConfiguration(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  };

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    };
  }

  const config = {
    headers,
  };

  return Api.get(`${data.branchId}/settings/get-pos-configuration`, config);
}
export async function storeConfiguration(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  };

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    };
  }

  const config = {
    headers,
  };

  return Api.post(`${data.branchId}/settings/store-pos-configuration`, data, config);
}

export async function getSettings(data) {
  let headers = {
    'Content-type': 'application/json',
    'Accept': 'application/json',
  };

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    };
  }

  const config = {
    headers,
  };

  return Api.get(`${data.branchId}/settings/get-kds-configuration`, config);
}

export async function saveSettings(data) {
  console.log(data)
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  };

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    };
  }

  const config = {
    headers,
  };

  return Api.post(`${data.branchId}/settings/store-kds-configuration`, data, config);
}
