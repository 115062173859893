<template>
  <div style="max-height: 100vh; overflow-y: auto; overflow-x: hidden ;">
    <b-card-group deck>
      <b-card isFooter noButtonHeader header="My Profile" header-tag="header" style="background-color: #F4EADE;">
        <div class="d-flex">
          <div class="mr-3"></div>
          <div class="detail">
            <p><b>Name:</b> {{ getDetailUser.data.name }}</p>
            <p><b>Email:</b> {{ getDetailUser.data.email }}</p>
            <p><b>Role:</b> {{ getDetailUser.data.roles[0].name }}</p>
            <p><b>Branch:</b> {{ getDetailUser.data.branch.name }}</p>
            <p><b>Phone:</b> {{ getDetailUser.data.branch.phone }}</p>
            <p><b>Address:</b> {{ getDetailUser.data.branch.address }}</p>
          </div>
        </div>
        <ButtonLogout classBtn="btn mr-2 my-1 text-white shadow" style="background-color:#0f3442;">
          <span class="mr-2">Logout</span>
          <b-icon icon="box-arrow-in-left" scale="1" />
        </ButtonLogout>
      </b-card>

      <b-card header="DESK Payment configuration" header-tag="header" style="background-color: #F4EADE;">
        <div class="d-flex">
          <div class="mr-3"></div>
          <div class="detail">
            <b-form-group
              label="IP configuration"
              v-model="configSettings.ip"
              description="IP configuration of the DESK machine"
            >
              <b-form-input v-model="configSettings.ip"></b-form-input>
            </b-form-group>
            <b-form-group label="Port" description="PORT configuration of the DESK machine">
              <b-form-input v-model="configSettings.port"></b-form-input>
            </b-form-group>
            <b-button  @click="updateConfig" style="background-color:#0f3442;">Save</b-button>
          </div>
        </div>
      </b-card>
    </b-card-group>
    <div class="col-6 mt-4">
      <b-card-group deck>
  
        <b-card header="DESK Printer configuration" header-tag="header" :class="'ml-0'" style="background-color: #F4EADE;">
          <div class="d-flex">
            <div class="mr-3"></div>
            <div class="detail">
              <b-form-group
                label="IP configuration"
                v-model="printerIp"
                description="IP configuration of the printer machine"
              >
                <b-form-input v-model="printerIp"></b-form-input>
              </b-form-group>
              <b-form-group label="Port" description="PORT configuration of the printer machine">
                <b-form-input v-model="printerPort"></b-form-input>
              </b-form-group>
              <b-button  @click="saveSettingsData" style="background-color:#0f3442;">Save</b-button>
            </div>
          </div>
        </b-card>
        
      </b-card-group>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import mixins from '@/components/mixins/swal';
import ButtonLogout from '@/components/molecules/ButtonLogout';
import { getConfiguration, storeConfiguration, getSettings, saveSettings } from '../../../apis/User';

export default {
  name: 'MyProfileCard',
  mixins: [mixins],
  components: {
    ButtonLogout,
  },
  data() {
    return {
      configSettings: {
        ip: null,
        port: null,
      },
      printerIp: null,
      printerPort: null
    };
  },
  mounted() {
    this.getSettingsData();
    this.configuration();
  },
  computed: mapGetters('user', ['getDetailUser']),
  methods: {
    ...mapActions('user', ['posConfiguration']),
    configuration() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        token: token,
      };
      getConfiguration(requestData)
        .then((response) => {
          if (response.status) {
            this.posConfiguration(response.data.data);
            this.configSettings.ip = response.data.data.ip;
            this.configSettings.port = response.data.data.port;
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },
    updateConfig() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        pos_configuration: {
          ip: this.configSettings.ip,
          port: this.configSettings.port,
        },
        token: token,
      };

      storeConfiguration(requestData)
        .then((response) => {
          if (response.status) {
            this.configuration();
            this.toastSuccess('Configuration updated successfully!');
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },
    saveSettingsData() {
      const token = localStorage.getItem('token')
      const branchId = localStorage.getItem('branchId');
      saveSettings({kds_configuration: {port: this.printerPort, ip:this.printerIp}, token: token, branchId: branchId})
        .then((response) => {
          
          if (response.status) {
            this.settings = response.data;
          }
          this.getSettingsData()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSettingsData() {
      const token = localStorage.getItem('token')
      const branchId = localStorage.getItem('branchId');

      getSettings({token: token, branchId: branchId})
        .then((response) => {
          if (response.status) {
            console.log(response.data.data)
              const data = response.data.data
                this.printerPort = data.port,
                this.printerIp = data.ip
          }
          this.toastSuccess('Configuration updated successfully!');
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    }
  },
};
</script>

<style scoped>




</style>
