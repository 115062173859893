<template>
    <div class="position-relative bg-danger">
      <img
        src="frame.jpg"
        alt="Image"
        style="position: fixed; width: 100vw; height: 100vh"
      />
      <div class="position-absolute w-100 h-100">
        <div class="d-flex align-items-center">
          <p v-if="connected"
            class="d-flex align-items-center px-3 py-1 m-0 mx-3 border border-success bg-success text-white font-bold"
            :style="{borderRadius: '30px'}"
          >
            <span class="rounded bg-white p-1" :style="{width: '10px', height: '10px'}">&nbsp;</span>
            &nbsp;Connected
          </p>

          <p v-if="!connected"
            class="d-flex align-items-center px-3 py-1 m-0 mx-3 border border-danger bg-danger text-white font-bold"
            :style="{borderRadius: '30px'}"
          >
            <span class="rounded bg-white p-1" :style="{width: '10px', height: '10px'}">&nbsp;</span>
            &nbsp;Disconnected
          </p>

          <button
            @click="reload"
            class="btn btn-dark m-3 px-3 py-1"
            style="background-color: #0F3442; color: white; font-size: 0.875rem"
          >
          Refresh
        </button>

        </div>
        <div
          class="position-fixed"
          style="top: 0; left: 50%; height: 100vh; border: 3px solid black; border-color: #0F3442"
        ></div>
        <div class="row pt-4">
          <div class="col-6 d-flex flex-column align-items-center">
            <h6
              class="text-sm p-4 rounded-lg"
              style="background-color: #0F3442; color: #F4EADE"
            >
              PREPARING
            </h6>
            <div
              class="mt-4 font-weight-bold"
              v-for="(order, index) in reversePendingOrders"
              :key="index"
            >
              {{ order.code }}
            </div>
          </div>
  
          <div class="col-6 d-flex flex-column align-items-center">
            <h6
              class="text-sm p-4 rounded-lg"
              style="background-color: #0F3442; color: #F4EADE"
            >
              READY TO COLLECT
            </h6>
            <div
              class="mt-4 font-weight-bold"
              v-for="(order, index) in reverseReadyOrders"
              :key="index"
            >
                {{ order.code }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
/* eslint-disable */
import { mapActions } from "vuex";
// import { getPendingOrders, getReadyOrders } from "../api/orders";

export default {
  data() {
    return {
      ongoingOrders: [],
      readyOrders: [],
      branch: this.$store.getters["auth/branch"],
      loading: false,
      timers: {},
      connected: true
    };
  },

  computed: {
    reversePendingOrders() {
      return this.ongoingOrders.sort((a,b) => b.code - a.code);
    },
    reverseReadyOrders() {
      return this.readyOrders.sort((a,b) => b.code - a.code);
    }
    // filteredReadyOrders() {
    //   const currentTime = this.getCurrentDateTime();
    //   return this.readyOrders.filter((order) => {
    //     if (order.ready_at || order.serve_at) {
    //       const secondsDifference = this.findSecondsDifference(currentTime, order.ready_at);
    //       console.log('condition', secondsDifference < 120);
    //       return secondsDifference < 120;
    //     }
    //     return true;
    //   });
    // },
  },
  watch:{
    connected: {
      handler (newValue, oldValue) {
        if(!newValue) {
          setTimeout(() => {
            window.location.reload()
          }, 20000)
        } 
      }
    },
  },
  created() {
    if (!this.branch) {
      this.branch = 1;
    }

    window.Echo.connector.pusher.connection.bind('state_change', (states) => {
        if(states.current == 'unavailable') {
          this.connected = false;
        }
    });

    window.Echo.connector.pusher.connection.bind('disconnected', () => {
        this.connected = false;
        console.log('Socket disconnected');
        // You can also display UI notifications, trigger reconnection logic, etc.
    });

    // Optionally, listen for 'connected' and 'reconnecting' events
    window.Echo.connector.pusher.connection.bind('connected', () => {
        this.connected = true;
        console.log('Socket connected');
    });

    window.Echo.connector.pusher.connection.bind('reconnecting', (attempt) => {
        this.connected = false;
        console.log(`Attempting to reconnect... (Attempt: ${attempt})`);
    });

    // Assuming you've already set up Laravel window.Echo with Pusher
    window.Echo.connector.pusher.connection.bind('error', (error) => {
        console.error('WebSocket connection error:', error);
        this.connected = false;
    });

    window.Echo.channel("order-placing-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderPlacedEvent",
      async (e) => {
        const order = JSON.parse(e.order);
        if(!this.ongoingOrders.find((data) => data.id == order.id)) {
          this.ongoingOrders.push(order);
        }
      }
    );

    window.Echo.channel("order-cancelled-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderCancelledEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.ongoingOrders = this.ongoingOrders.filter(
          (order) => order.id !== data.id
        );
        this.readyOrders = this.readyOrders.filter(
          (order) => order.id !== data.id
        );
      }
    );

    window.Echo.channel("ready-order-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderReadyEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        if(!this.readyOrders.find((order) => order.id == data.id)) {
          this.readyOrders.push(data);
        }
        this.ongoingOrders = this.ongoingOrders.filter(
          (order) => order.id !== data.id
        );

        // setTimeout(() => {
        //   const index = this.readyOrders.indexOf(data);
        //   if (index !== -1) {
        //     this.readyOrders.splice(index, 1);
        //   }
        // }, 120000);

        const codeArray = Array.from(data.code);
        const synth = window.speechSynthesis;
        const codeMessage = `${codeArray.join(", ")}`;

        const utterance = new SpeechSynthesisUtterance(codeMessage);
        utterance.rate = 0.6;
        utterance.pause = 500;
        try {
          synth.speak(utterance);
        } catch (error) {
          console.log(error);
        }
      }
    );

    window.Echo.channel("send-reminder-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\SendReadyOrderReminderEvent",
      async (e) => {
        const data = JSON.parse(e.order);

        const codeArray = Array.from(data.code);
        const synth = window.speechSynthesis;
        const codeMessage = `${codeArray.join(", ")}`;

        const utterance = new SpeechSynthesisUtterance(codeMessage);
        utterance.rate = 0.6;
        utterance.pause = 500;
        try {
          synth.speak(utterance);
        } catch (error) {
          console.log(error);
        }
      }
    );

    window.Echo.channel("order-served-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderServedEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.handleOrderServed(data);
      }
    );
  },

  mounted() {
    this.getOrders();
    this.getReadyOrders();
  },

  beforeUnmount() {
    Object.values(this.timers).forEach((timer) => {
      clearTimeout(timer);
    });
  },

  methods: {
    reload() {
      window.location.reload();
    },
    async getReady() {
      return fetch('https://master.harveys.sg/api/' + "1" + "/order/get-ready-orders", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
      }).then((response) => response.json());
    },

    async getPending() {
      return fetch('https://master.harveys.sg/api/' + '1' + "/order/get-pending-orders", {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
      }).then((response) => response.json());
    },

    getOrders() {
      this.getPending()
        .then((response) => {
          if (response.status) {
            this.ongoingOrders = response.data;
          }
        })
        .catch((error) => {
            console.log(error)
        });
    },

    getReadyOrders() {
      this.getReady()
        .then((response) => {
          if (response.status) {
            this.readyOrders = response.data;
          }
        })
        .catch((error) => {
            console.log(error)
          
        });
    },

    handleOrderServed(data) {
      console.log(data, this.readyOrders);
      if (this.timers[data.id]) {
        clearTimeout(this.timers[data.id]);
      }

      this.timers[data.id] = setTimeout(() => {
        const index = this.readyOrders.findIndex(
          (order) => order.id == data.id
        );
        if (index != -1) {
          this.readyOrders.splice(index, 1);
          delete this.timers[data.id];
        }
      }, 1000);
    },

    getCurrentDateTime() {
      const singaporeOffset = 8 * 60 * 60 * 1000;
      const currentDate = new Date(Date.now() + singaporeOffset);
      const formattedDateTime = currentDate
        .toISOString()
        .replace("T", " ")
        .substring(0, 19);
      return formattedDateTime;
    },

    convertToSingaporeTime(utcTimeString) {
      const utcDate = new Date(utcTimeString);
      const singaporeOffset = 8 * 60;
      const singaporeTime = new Date(
        utcDate.getTime() + singaporeOffset * 60 * 1000
      );
      const formattedTime = singaporeTime
        .toISOString()
        .replace("T", " ")
        .substring(0, 19);

      return formattedTime;
    },

    findSecondsDifference(currentTime, orderReadyAt) {
      const currentTimeObj = new Date(currentTime);
      const orderReadyAtObj = new Date(orderReadyAt);
      const differenceMs = currentTimeObj - orderReadyAtObj;
      const seconds = Math.abs(differenceMs) / 1000;

      return seconds;
    },
  },
};
</script>
  